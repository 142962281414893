<template>
  <div>
    <a-form class="simiot-nested-search-form" :form="form" @submit="handleSearch">
      <a-row :gutter="24">
        <a-col :span="7">
          <a-form-item label="协议名称">
            <a-input
              v-decorator="['name', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="7">
          <a-form-item label="协议主体">
            <a-select
              :loading="loadingContractingPartyOptions"
              show-search
              :filter-option="filterOption"
              v-decorator="['contracting_party_id']"
            >
              <a-select-option
                v-for="contracting_party in contractingPartyOptions"
                :key="contracting_party.id"
                :value="contracting_party.id"
              >
                {{ contracting_party.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import { findContractingPartyOptions } from '@/api/contracting_party'

export default {
  name: 'SearchCardAgreement',
  data() {
    return {
      form: this.$form.createForm(this, { name: 'card_agreement_search' }),
      contractingPartyOptions: [],
      loadingContractingPartyOptions: true
    }
  },
  created() {
    this.fetchContractingPartiesOption()
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      this.$emit('submit', this.form.getFieldsValue())
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    fetchContractingPartiesOption() {
      this.loadingContractingPartyOptions = true
      findContractingPartyOptions().then((res) => {
        if (res.code === 0) {
          this.contractingPartyOptions = res.data
        }
        this.loadingContractingPartyOptions = false
      })
    }
  }
}
</script>
